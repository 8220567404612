import { cache, createAsync, useParams } from "@solidjs/router";
import { getGiftBoxRedirectionRouteData } from "~/server/data/gift_box_redirection_route_data";

export type GiftBoxType = "MULTI_BRAND" | "SINGLE_BRAND";

export default function Gift() {
  const GiftRedirectionRouteData$C = cache(
    getGiftBoxRedirectionRouteData,
    "GiftRedirectionRouteData"
  );

  const params = useParams();
  const giftingKey = params.giftingKey as string;

  const routeData = createAsync(
    async () => {
      return await GiftRedirectionRouteData$C(giftingKey);
    },
    { deferStream: true }
  );
}
