"use server";

import { redirect } from "@solidjs/router";
import { getGiftBoxType } from "../apis/server_apis";
import { GiftBoxType } from "../types/gift";

export const getGiftBoxRedirectionRouteData = async (
  giftId: string
): Promise<GiftBoxType> => {
  const result = await getGiftBoxType(giftId, undefined, {
    isUnauthenticated: true,
  });

  if (result.type === "MULTI_BRAND") {
    throw redirect(`/gift-box/m/${giftId}`);
  } else {
    throw redirect(`/gift-box/s/${giftId}`);
  }
};
